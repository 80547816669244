@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h111 {
  margin-top:-20px;
font-size: 90px;
line-height: calc(20px + 20vh);
text-shadow: 0 0 5px rgb(130, 171, 238), 0 0 15px rgb(130, 171, 238), 0 0 20px rgb(130, 171, 238), 0 0 40px rgb(130, 171, 238), 0 0 60px #c28fe9, 0 0 10px rgb(94, 152, 244), 0 0 98px #c28fe9;
  color: rgb(130, 171, 238);
font-family: "Sacramento", cursive;
text-align: center;
animation: blink 3s infinite;
-webkit-animation: blink 3s infinite;
}
@-webkit-keyframes blink {
20%,
24%,
55% {
  color: transparent;
  text-shadow: none;
}

0%,
19%,
21%,
23%,
25%,
54%,
56%,
100% {
    text-shadow: 0 0 5px rgb(130, 171, 238), 0 0 15px rgb(130, 171, 238), 0 0 20px rgb(130, 171, 238), 0 0 40px rgb(130, 171, 238), 0 0 60px #c28fe9, 0 0 10px rgb(94, 152, 244), 0 0 98px #c28fe9;
    color: rgb(130, 171, 238);
}
}

@keyframes blink {
20%,
24%,
55% {
  color: transparent;
  text-shadow: none;
}

0%,
19%,
21%,
23%,
25%,
54%,
56%,
100% {

    text-shadow: 0px 0 10px #eed0c2, 0 0 10px #edbda6, 0 0 10px #edbca6c5, 0 0 10px , 0 0 10px , 0 0 10px , 0 0 20px ;
    color: #6e3f24c2;
}
}